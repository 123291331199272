import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Container, Card, Flex, Box, Link } from 'theme-ui';
import { PageStructure } from '../../components/help-center/PageStructure';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <PageStructure layoutProps={props} title="Referral Program">
        {props.children}
    </PageStructure>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`It is incredibly simple to participate in our referral program.`}</p>
    <p>{`Both existing and referred customers earn free review credits.`}</p>
    <h2 {...{
      "id": "how-it-works"
    }}>{`How it Works`}</h2>
    <p>{`There are two ways to use the referral program.`}</p>
    <h4 {...{
      "id": "automated-referral-setting"
    }}>{`Automated Referral Setting`}</h4>
    <p>{`With the automated referral setting active there is no effort required by you. `}</p>
    <p>{`SRM will automatically send a promotional email, containing your referral link, to customers at the end of the review request flow.`}</p>
    <p>{`Any time the email results in a review you get review credits.`}</p>
    <p>{`Activating the setting is an easy way to lower your SRM bill.`}</p>
    <p>{`The setting can be activated by logging in to your existing SRM account and going to the `}<a target="_blank" href='https://app.simplereviewmanagement.com/referral-program'>{`referral program page`}</a>{`.`}</p>
    <p>{`When active, the Automated Referral Setting looks like the following.`}</p>
    <img style={{
      "maxWidth": "400px"
    }} src="/help-center/automated_referral_setting.png" />
    <h4 {...{
      "id": "share-the-link-yourself"
    }}>{`Share The Link Yourself`}</h4>
    <p>{`Log into your existing Simple RM account and go to the `}<a target="_blank" href='https://app.simplereviewmanagement.com/referral-program'>{`referral program page`}</a>{`.`}</p>
    <p>{`There you can retrieve your personal referral link, copy it and share it with whoever you would like.`}</p>
    <p>{`Any time someone uses it to create an account, you and the new customer will receive free review credits.`}</p>
    <p>{`You can keep track of your referrals on the `}<a target="_blank" href='https://app.simplereviewmanagement.com/referral-program'>{`referral program page`}</a>{`.`}</p>
    <h2 {...{
      "id": "free-review-credits"
    }}>{`Free Review Credits`}</h2>
    <p>{`Think of review credits as a coupon for a free generated review.`}</p>
    <p>{`They are only awarded when the referred customer finishes creating their account.`}</p>
    <p>{`This includes creating a user account, creating one company and setting up billing for that company.`}</p>
    <h2 {...{
      "id": "how-to-redeem-review-credits"
    }}>{`How to Redeem Review Credits`}</h2>
    <p>{`Review credits are redeemed automatically. The process requires no effort on your part.`}</p>
    <p>{`Any time you have an unused review credit available and the app generates a review the review credit will be redeemed instead of you being charged.`}</p>
    <h2 {...{
      "id": "where-to-find-the-referral-program-page"
    }}>{`Where to Find the Referral Program Page`}</h2>
    <img style={{
      "maxWidth": "400px"
    }} src="/help-center/referral-program-link.png" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      